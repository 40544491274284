import React from "react";
import styled from "@emotion/styled"
import { Link } from "gatsby";
import { FaHamburger } from "react-icons/fa";

export const Nav = styled.nav`
    transition: 0.8;
    display: flex;
    height: 80px;
    justify-content: center;
    font-size: 1rem;
    position: sticky;
    top: 0;
    left:0;
    z-index: 999;
    background-color:#fff;
    box-shadow: 1px 1px 5px rgb(0 0 0 / 20%);
    @media (max-width: 960px) {
        transition: 3s all ease;
        background-color:#fff;
    }
`
export const NavbarContainer = styled.div`
        display: flex;
        justify-content: space-between;
        height: 80px;
        z-index: 1;
        width: 100%;
`
export const NavLogo = styled(Link)`
        color: #fff;
        justify-self: flex-start;
        cursor: pointer;
        text-decoration: none;
        font-size: 1.8rem;
        display: flex;
        align-items: center;
        font-weight: 600;
`
export const NavIcon = styled(FaHamburger)`
margin: 0 0.5rem;
padding: 1.6rem;
`
export const MobileIcon  = styled.div`
display: none;
@media (max-width: 960px) {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-60%,100%);
    font-size: 1.5rem;
    cursor: pointer;
}
`
export const NavMenu = styled.ul`
display: flex;
align-items: center;
list-style: none;
text-align: center;
@media (max-width: 960px) {
    flex-direction:  column;
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    left:0;
    top: ${({click}) => (click ? "0" :"-2500px")}; 
    opacity: 1;
    transition: all 0.5s ;
}
`
export const NavMenuProject = styled.ul`
display: flex;
align-items: center;
list-style: none;
text-align: center;
@media (max-width: 960px) {
    flex-direction:  column;
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    left:0;
    top: ${({click}) => (click ? "0" :"-2500px")}; 
    opacity: 1;
    transition: all 0.5s ;
    display: none;
}
`
export const NavItem = styled.li`
height: 80px;
@media (max-width: 960px) {
    width:100%
}
`
export const NavLinks = styled(Link)``