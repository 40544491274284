import React from "react";
import Coverfb from "images/facebook.svg";
import Coveryb from "images/youtube.svg";
const Footer = () => {
  // const currentYear = new Date().getFullYear();
  return (
    <footer className="Footer mt-16">
      <div className="container">
        <div className="footer__flex">
          <p>Copyright © 2022 Alphaland Group All rights reserved</p>
          <div className="footer__image">
              <ul>
                  <li>
                      <a href="https://www.facebook.com/alphaland.com.vn" target="_blank">
                          <img src={Coverfb}/>
                      </a>
                  </li>
                  <li>
                      <a href="https://www.youtube.com/channel/UCvwWr8yrdepDKIiFlAUYJKw" target="_blank">
                          <img src={Coveryb}/>
                      </a>
                  </li>
              </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
