import React from "react";
import Footer from "./footer";
import Navbar from "./navbar";
import { Helmet } from "react-helmet"
import { GatsbySeo } from 'gatsby-plugin-next-seo';
import { useStaticQuery, graphql } from "gatsby";
import favicon from '../images/favicon/favicon.ico'
const Layout = ({ children }) => {

  const { strapiGlobal } = useStaticQuery(graphql`
    query {
      strapiGlobal {
        siteName
        siteDescription
        defaultSeo {
          metaTitle
          metaDescription
          metaImage {
            alternativeText
            localFile {
              url
            }
          }
          keywords
          metaRobots
          metaViewport
          canonicalURL
          metaSocial {
            socialNetwork
            title
            description
            coverImage {
              localFile {
                url
              }
            }
          }
        }
      }
    }
  `)

  return (
    <>
      <Helmet
        link={[
          {
            rel: "icon",
            href: favicon,
          },
        ]}
      />
        <Helmet>
          <meta name="facebook-domain-verification" content="9vndbtj5sgxud5ky6p7wwoy0r879m6" />
          <script
            dangerouslySetInnerHTML={{
              __html: `
              !function (w, d, t) {
                w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++
            )ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};n=document.createElement("script");n.type="text/javascript",n.async=!0,n.src=i+"?sdkid="+e+"&lib="+t;e=document.getElementsByTagName("script")[0];e.parentNode.insertBefore(n,e)};
                ttq.load('CAKP9KBC77U4MPLVSMG0');
                ttq.page();
              }(window, document, 'ttq');
              `,
            }}
          />
        </Helmet>
      <GatsbySeo
        titleTemplate = '%s | Alphaland Group'
        description={strapiGlobal.defaultSeo.metaDescription || ''}
        canonical={strapiGlobal.defaultSeo.canonicalURL || ''}
        openGraph={{
          url: 'https://www.alphaland.com.vn',
          title: strapiGlobal.defaultSeo.metaTitle,
          description: strapiGlobal.defaultSeo.metaDescription,
          images: [
            {
              url: strapiGlobal.defaultSeo.metaImage.localFile.url,
              alt: strapiGlobal.defaultSeo.metaImage.alternativeText,
            }
          ],
          site_name: strapiGlobal.siteName,
        }}
      />
      <div className="flex min-h-screen flex-col justify-between text-neutral-900">
        <div>
          <Navbar />
          <div className="overflow__X"> 
            {children}
            
          </div>
          
          </div>
        <Footer />
      </div>
    </>
  );
};

export default Layout;
