import React,{useEffect,useState} from "react";
import {FaBars, FaTimes} from "react-icons/fa";
import { IconContext } from "react-icons/lib";
import { FaHamburger } from "react-icons/fa";
import {
  MobileIcon,
  Nav,
  NavbarContainer,
  NavIcon,
  NavItem,
  NavLinks,
  NavLogo,
  NavMenu,
}from "../components/nav/NavbarElements"
import { Link } from "gatsby";
import "../styles/navbar.css";
import Cover from "../images/alphaland_logo.svg";
const Navbar = () => {
  const [click, setClick] = useState(false)
  const [scroll, setScroll] = useState(false)

  const handleClick = () => setClick(!click)
  const closeMobileMenu = () => setClick(false)

  const changeNav = () => {
    if(window.scrollY >= 80){
      setScroll(true)
    }else{
      setScroll(false)
    }
  }
  useEffect(()=>{
    changeNav()
    window.addEventListener("scroll", changeNav)
  },[])
  return (
    <>
      <IconContext.Provider  value={{color: "#4A4A4A"}}>
        <Nav className="nav" active={scroll}  click={click}>
          <NavbarContainer className=" container">
            <div className="icon_logo" to="/" onClick={closeMobileMenu}>
              <Link title="tran-chu" to="/"><img src={Cover}/></Link>
            </div>
            <MobileIcon onClick={handleClick}>
              {click ? <FaTimes/> : <FaBars/>}
            </MobileIcon>
            <NavMenu className="nav__menu" onClick={handleClick} click={click}>
              <NavItem>
              <NavLinks title="Trang-chu" className="nav__href" to="/">
                  Trang Chủ
                </NavLinks>
                <NavLinks title="Ve-chung-toi" className="nav__href" to="/ve-chung-toi">
                  Về Chúng Tôi
                </NavLinks>
                <NavLinks title="Du-an" className="nav__href" to="/du-an">
                  Dự Án
                </NavLinks>
                <NavLinks title="Tin-tuc" className="nav__href" to="/tin-tuc/1">
                  Tin Tức
                </NavLinks>
                <NavLinks title="Tuyen-dung" className="nav__href" to="/tuyen-dung">
                  Tuyển Dụng
                </NavLinks>
                <NavLinks title="Lien-he" className="nav__href" to="/lien-he">
                  Liên Hệ
                </NavLinks>
                <div className="nav__logo-mobile">
                    <img src={Cover}  />
                    <h4>Thông tin liên hệ:</h4>
                    <div className="nav__title-decsMobile">
                      <p>0932657979</p>
                      <p>info@alphaland.com.vn</p>
                      <p>111 Trương Văn Bang, Phường Thạnh Mỹ Lợi, Thành phố Thủ Đức, Hồ Chí Minh</p>
                    </div>
                </div>
              </NavItem>
              
            </NavMenu>
          </NavbarContainer>
        </Nav>
      </IconContext.Provider>
    </>
  );
};

export default Navbar;